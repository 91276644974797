import './hall-interact.scss'

import util from '@/utils/util'

export default {
  props: {
    typeIcon: Array,
  },
  data: () => ({
    infors: [],
    userId: '3a91fb0c06764be7b85969c8fcf6207d',
    fmt: 'yyyy-MM-dd HH:mm',
    paging: {
      type: 2,
      page: 1,
      rows: 5,
      last: false
    }
  }),
  created() {

  },
  methods: {
    queryCommentInfor(){
      let _this = this;
      if (_this.paging.last){
        return;
      }
      let params = {
        type: _this.paging.type,
        page: _this.paging.page,
        rows: _this.paging.rows,
        status: 0
      };
      _this.$api.infor.queryInforList(params).then(res => {
        if(res.data){
          res.data.data.forEach((data, i) =>{
            switch (data.infor_target_type) {
              case 201: //纪念馆
                data.icon = _this.typeIcon[3].icon;
                break;
              case 202: //悼文
                data.icon = _this.typeIcon[1].icon;
                break;
              case 203: //传记
                data.icon = _this.typeIcon[2].icon;
                break;
              case 204: //荣誉
                data.icon = _this.typeIcon[0].icon;
                break;
              default:
                break;
            }
            data.extend_info = JSON.parse(data.extend_info);
            _this.infors.push(data);
          });
          _this.paging.last = (_this.paging.page >= res.data.paging.pages);
          if (!_this.paging.last){
            _this.paging.page++
          }
        }
      })
    },
    _renderBackTitle(infor) {
      if (infor.send_user.id === this.userId) {
        return (<div class="read-to">您献上了寄语：</div>)
      } else {
        return (<div class="read-to">{infor.title}：</div>)
      }
    }
  },
  render() {
    return (
      <div class="comment-back">
        <div class="comment-back-list" v-infinite-scroll={()=> this.queryCommentInfor()}>
          {
            this.infors.map((infor) =>
              <div class="back-list" key={infor.id}>
                <div class="list-top">
                  <span><img src={infor.icon} />{infor.extend_info.hall_name}</span>
                  <span class="list-time">{util.formatDate(infor.send_time, this.fmt)}</span>
                </div>
                <div class="list-text">
                  {this._renderBackTitle(infor)}
                  <div class="read-to-text">{infor.content}</div>
                  {(infor.send_user.id === this.userId) ? <div class="read-form" onClick={() => showMessage(infor)}><i class="sea-iconfont">&#xe62c;</i>一键回复</div> : <div></div> }
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
