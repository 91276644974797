
import './hall-interact.scss'

export default {
  props: {
    typeIcon: Array,
  },
  data: () => ({
    infors: [],
    paging: {
      type: 3,
      page: 1,
      rows: 5,
      last: false
    }
  }),
  methods: {
    queryApplyInfor() {
      let _this = this;
      if (_this.paging.last){
        return;
      }
      let params = {
        type: _this.paging.type,
        page: _this.paging.page,
        rows: _this.paging.rows,
        status: 0
      };
      _this.$api.infor.queryInforList(params).then(res => {
        if (res.data) {
          res.data.data.forEach((data, i) => {
            data.extend_info = JSON.parse(data.extend_info);
            switch (data.infor_target_type) {
              case 301: //图片审核
                data.icon = _this.typeIcon[0].icon;
                break;
              case 302: //视频审核
                data.icon = _this.typeIcon[1].icon;
                break;
              case 303: //传记审核
                data.icon = _this.typeIcon[2].icon;
                break;
              case 304: //悼文审核
                data.icon = _this.typeIcon[3].icon;
                break;
              case 305: //荣誉
                data.icon = _this.typeIcon[4].icon;
                break;
              case 306: //亲友圈审核
                data.icon = _this.typeIcon[4].icon;
                break;
              default:
                break;
            }
            _this.infors.push(data);
          });
          _this.paging.last = (_this.paging.page >= res.data.paging.pages);
          if (!_this.paging.last){
            _this.paging.page++
          }
        }
      })
    },
    goAudit(infor) {

    }
  },
  render() {
    return (
      <div class="content-approval">
        <div class="content-approval-list" v-infinite-scroll={() => this.queryApplyInfor()}>
          {
            this.infors.map((infor) =>
              <div class="back-list">
                <div class="list-top">
                  <span><img src={infor.icon} />{infor.title}</span>
                  <span class="list-time">{infor.send_time}</span>
                </div>
                <div class="list-text">
                  <div class="list-user"><label>用户名：</label><span>{infor.extend_info.username}</span></div>
                  <div class="list-user"><label>所在馆名：</label><span>{infor.extend_info.hall_name}</span></div>
                  <div class="list-user"><label>与逝者关系：</label><span>{infor.extend_info.relation}</span></div>
                  <div class="read-form" onClick={() => this.goAudit(infor)}><i class="sea-iconfont">&#xe62c;</i>查看详情</div>
              </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
