<template>
  <div class="hall-interact">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="内容审核" name="first"></el-tab-pane>
      <el-tab-pane label="评论回复" name="second"></el-tab-pane>
      <el-tab-pane label="祭拜感谢" name="third"></el-tab-pane>
    </el-tabs>
    <component
      :is="interactView"
      :typeIcon="typeIcon"
    ></component>
  </div>
</template>

<script>
  import WorshipThank from '@/components/hall-interact/worship-thank.js'
  import CommentBack from '@/components/hall-interact/comment-back.js'
  import ContentApproval from '@/components/hall-interact/content-approval.js'
  export default {
    name: 'hall-interact',
    components: {
      WorshipThank,
      CommentBack,
      ContentApproval
    },
    data() {
      return {
        interactView: WorshipThank,
        activeName: 'third',
        typeIcon: [
          {icon: require('../../../assets/images/hall/interact/read-biography-icon.png')},
          {icon: require('../../../assets/images/hall/interact/read-honor-icon.png')},
          {icon: require('../../../assets/images/hall/interact/read-eulogy-icon.png')},
          {icon: require('../../../assets/images/hall/interact/read-kith-icon.png')},
          {icon: require('../../../assets/images/hall/interact/read-voice-icon.png')}]
      }
    },
    methods: {
      handleClick(tab, event) {
        switch (parseInt(tab.index)) {
          case 0 :
            this.interactView = ContentApproval;
            break;
          case 1 :
            this.interactView = CommentBack;
            break;
          case 2 :
            this.interactView = WorshipThank;
            break;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.hall-interact {
  /deep/ .el-tabs__item {
    font-size: 18px;
  }
}
</style>
