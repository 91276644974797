
import './hall-interact.scss'

export default {
  data: () => ({
    count: [],
    allChecked: false
  }),
  created() {

  },
  methods: {
    getWorshipList() {
      setTimeout(()=> {
        for (let i = 0; i < 10; i++) {
          this.count.push({name: `千库酱${i}`, id: i, check: false})
        }
        console.log(this.count)
      },2000)
    },
    isCheck(data) {
      data.check = !data.check
    },
    allCheck() {
      this.allChecked = !this.allChecked;
      this.count.forEach((item) => {
        item.check = !item.check
      })
    }
  },
  render() {
    return (
      <div class="worship-thank">
        <div class="worship-infinite-list" v-infinite-scroll={()=> this.getWorshipList()}>
          {
            this.count.map((data) =>
              <div class="list-item">
                <div class="item-check">
                  <span class={`check ${data.check ? 'check-choose' : ''}`} onClick={() => this.isCheck(data)}></span>
                </div>
                <div class="item-body">
                  <img class="worship-avater" src={require('../../assets/images/default-avatar.png')}/>
                  <div class="body-rig">
                    <div class="worship-name">{data.name}</div>
                    <div class="worship-content">赠送了一个大花圈<img src={require('../../assets/images/default-worship-flowers.png')}/></div>
                    <div class="worship-hall">大李纪念馆</div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div class="worship-thank-btn">
          <div class="item-check"  onClick={() => this.allCheck()}>
            <span class={`check ${this.allChecked ? 'check-choose' : ''}`}></span>
            <div class="checkAll">全选</div>
          </div>
          <div class="btn"><button type="button">一键感谢</button></div>
        </div>
      </div>
    )
  }
}
